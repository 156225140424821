<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">业务清单</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <table class="tg">
          <thead>
            <tr>
            <th class="tg-fbr6" rowspan="4">1</th>
            <th class="tg-t83w" rowspan="4">规划建筑景观</th>
            <th class="tg-fbr6">空间规划：战略规划、五年发展规划、总体规划、详细规划、专项规划、公园城区规划建设、规划实施评估、城市体检评估、节约用地评价、社会稳定性评估、建设项目工程用地预审、城市更新、城中村改造、保障房建设、平急两用、历史保护、产业发展规划、产业策划、概念方案、日照分析、乡村振兴全域土地综合整治、存量资产盘活利用规划策划等。</th>
            <th class="tg-zohn" rowspan="3">大连市国土空间规划设计有限公司</th>
            <th class="tg-zohn" rowspan="3">韩迪 13591369501</th>
            </tr>
            <tr>
            <th class="tg-fbr6">选址论证：对建设用地（包括线路类）条件进行综合论证，生成规划选址意见、规划条件或规划要求。</th>
            </tr>
            <tr>
            <th class="tg-zohn">建筑设计：建筑设计方案、老建筑更新改造（立面、内部空间等）。</th>
            </tr>
            <tr>
            <th class="tg-zohn">风景园林工程设计：设计方案和施工图，包含道路绿化、风景园林、公园、城市绿地、口袋公园、鲜花摆放、立体花卉、河道景观、居住区绿化、楼院环境改造（不含建筑）、城市更新改造、城市街区景观化及公园化建设等。</th>
            <th class="tg-ltad">大连市城市交通设计研究院有限公司</th>
            <th class="tg-ltad">宁甲茁 13624245826</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td class="tg-6ec3" rowspan="4">2</td>
            <td class="tg-6ec3" rowspan="4">交通市政</td>
            <td class="tg-zohn">交通规划：交通安全评价、交通影响评价等咨询报告的编制，交通规划和优化方案，新建道路、停车场、人行天桥、地道、交通枢纽类规划选址论证等。</td>
            <td class="tg-0lax">大连市国土空间规划设计有限公司</td>
            <td class="tg-0lax">韩迪 13591369501</td>
            </tr>
            <tr>
            <td class="tg-zohn">市政道路工程设计：各级城市道路的新建及改扩建、道路维修、道路照明、停车场建设、背街小巷提升改造、老旧小区基础设施改造、交通工程、标志标线、信号灯、监控、交通护栏、交通管理设施维护、交通导改、新型智能化市政基础设施建设和改造等。</td>
            <td class="tg-cly1" rowspan="3">大连市城市交通设计研究院有限公司</td>
            <td class="tg-cly1" rowspan="3"><span style="font-weight:400;font-style:normal">宁甲茁 13624245826</span></td>
            </tr>
            <tr>
            <td class="tg-zohn">市政桥梁工程设计：各类市政桥梁、地下通道、人行天桥、桥梁维修加固、管道桥等。</td>
            </tr>
            <tr>
            <td class="tg-zohn">市政排水工程设计：雨污水等排水管道工程、管网综合、防洪工程、10万吨以下泵站、8万吨以下污水处理厂（站）、海绵城市改造、积水点改造、污水治理、河道治理、垃圾点整治等。</td>
            </tr>
            <tr>
            <td class="tg-nrix" rowspan="3">3</td>
            <td class="tg-nrix" rowspan="3">测绘</td>
            <td class="tg-cly1">不动产测量：房屋建筑面积测量、房屋室外立面测量，房屋室内面积测量以及界线测绘。</td>
            <td class="tg-cly1" rowspan="8">大连市国土空间规划设计有限公司</td>
            <td class="tg-cly1" rowspan="8">韩迪 13591369501</td>
            </tr>
            <tr>
            <td class="tg-cly1">工程测量：控制网测量，项目选址、工程设计等地形图测绘、竣工测量等；管道选址测绘、勘界测绘，大型建筑与桥梁、隧道等变形监测。</td>
            </tr>
            <tr>
            <td class="tg-cly1">市政工程测量：城市部件测量，道路、停车场、绿化区域测量与面积统计，地理信息采集与建库，城市实景三维建模。</td>
            </tr>
            <tr>
            <td class="tg-nrix" rowspan="5">4</td>
            <td class="tg-nrix" rowspan="5">数字信息</td>
            <td class="tg-cly1">全市规划建设权威数据信息服务：为建设投资项目提供权威准确规划、建设、现状、社会经济等数据信息及分析服务，提供用地信息、项目选址分析、合规性分析、可行性分析、动迁数据分析、土地价值分析等咨询服务。</td>
            </tr>
            <tr>
            <td class="tg-cly1">企业管理系统开发：包括OA办公系统、项目管理运维系统、文件信息加密管理系统、企业资源管理系统等。</td>
            </tr>
            <tr>
            <td class="tg-cly1">城建专业系统开发：城市运营、房屋、市政管线、燃气、水务、能源等信息化管理系统开发。</td>
            </tr>
            <tr>
            <td class="tg-cly1">智慧城市系统开发：智慧城市建设，三维数字城市、智慧社区、BIM/CIM系统建设与模型建设服务。</td>
            </tr>
            <tr>
            <td class="tg-cly1">地理空间大数据与人工智能服务：提供地形、影像、规划、项目审批、实景三维、手机信令等各类地理空间大数据的标准化处理与分析服务；提供国土空间大模型训练与应用服务。</td>
            </tr>
            <tr>
            <td class="tg-nrix" rowspan="8">5</td>
            <td class="tg-nrix" rowspan="8">咨询代建</td>
            <td class="tg-cly1">前期代建及全过程总控管理：（1）负责办理项目前期相关行政审批手续；（2）组织编制项目可行性研究报告，组织开展项目初步设计深度文件编制及设计方案优化工作，负责办理项目可行性研究报告（含概算）的报批手续；代办与该阶段相关的项目规划选址、用地预审等行政许可手续；（3）组织项目设计、勘察、采购、监理、施工等发包工作，协助项目单位签订并监督勘察、设计单位履行合同，负责建设工程合同的起草、谈判和管理工作，并按规定将相关合同向有关政府管理部门备案；（4）编制上报项目年度投资计划，按照年度投资计划，协助项目（法人）单位提出项目用款申请。</td>
            <td class="tg-cly1" rowspan="6">大连工程咨询中心有限公司<br /><br />大连市国土空间规划设计有限公司</td>
            <td class="tg-cly1" rowspan="6">白霁野13149877111<br /><br />韩迪 13591369501</td>
            </tr>
            <tr>
            <td class="tg-cly1">专项咨询：片区开发、园区开发、城中村、保障房、城市更新、村庄改造、老城区改造、农用地及建设用地提质建设、土地整理及开发的实施方案、项目建议书、可行性研究报告等。</td>
            </tr>
            <tr>
            <td class="tg-cly1">工程咨询：工程建设类项目的项目建议书、可行性研究报告、实施方案，初步设计及概算、节能评估、项目社会稳定风险评估等。</td>
            </tr>
            <tr>
            <td class="tg-cly1">投资决策综合咨询：集团自行开展或由主管部门、市政府下达的投资决策研究，包括内部资产、投资、融资、股权类等前期研究、投资机会研究、项目建议书、可行性研究报告、资产评估报告、实施方案、资金申请报告、项目申请报告、产业研究报告等。</td>
            </tr>
            <tr>
            <td class="tg-cly1">全过程咨询：对建设项目全生命周期提供的组织、管理、经济和技术等各有关方面的工程咨询服务。包括项目的全过程工程项目管理以及投资咨询、勘察、设计、造价咨询、招标代理、监理、运行维护咨询、BIM 咨询及其他咨询等全部或部分专业咨询服务。</td>
            </tr>
            <tr>
            <td class="tg-cly1">咨询评价评审：对项目建议书、可行性研究报告、各类申请报告、研究报告、实施方案等，组织开展咨询评价工作和专家评审工作。</td>
            </tr>
            <tr>
            <td class="tg-cly1">造价咨询：工程概预算编制及审核、工程量清单编制及审核、标底及招标控制价编制及审核、投标报价咨询、预结算编制及审核、工程经济纠纷鉴定、建设工程全过程造价管理。</td>
            <td class="tg-cly1">大连工程咨询中心有限公司</td>
            <td class="tg-cly1">田瑶 13190185292</td>
            </tr>
            <tr>
            <td class="tg-cly1">市政公用工程专业的工程咨询：项目建议书、可行性研究报告、实施方案等各类咨询报告的编制和评估评审。</td>
            <td class="tg-cly1">大连工程咨询中心有限公司<br /><br />大连市城市交通设计研究院有限公司</td>
            <td class="tg-cly1">田瑶 13190185292<br /><br />宁甲茁 13624245826</td>
            </tr>
            <tr>
            <td class="tg-nrix" rowspan="2">6</td>
            <td class="tg-nrix" rowspan="2">招标代理</td>
            <td class="tg-cly1">招标代理业务：货物、服务、工程招标全部工作。</td>
            <td class="tg-cly1" rowspan="2">大连工程咨询中心有限公司</td>
            <td class="tg-cly1" rowspan="2">田瑶 13190185292</td>
            </tr>
            <tr>
            <td class="tg-cly1">投标业务：货物、服务、工程投标文件编制工作等。</td>
            </tr>
            <tr>
            <td class="tg-nrix">7</td>
            <td class="tg-nrix">展示宣传</td>
            <td class="tg-cly1">展示宣传策划：展览展示、多媒体宣传片制作、三维动画、logo标识、网站公众号维护、专业论坛策划承办等。</td>
            <td class="tg-cly1">大连市国土空间规划设计有限公司</td>
            <td class="tg-cly1">韩迪 13591369501</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-cly1{text-align:left;vertical-align:middle}
.tg .tg-t83w{border-color:#333333;font-size:14px;text-align:center;vertical-align:middle}
.tg .tg-6ec3{font-size:14px;text-align:center;vertical-align:middle}
.tg .tg-fbr6{border-color:#333333;font-size:14px;text-align:left;vertical-align:middle}
.tg .tg-zohn{font-size:14px;text-align:left;vertical-align:middle}
.tg .tg-ltad{font-size:14px;text-align:left;vertical-align:top}
.tg .tg-0lax{text-align:left;vertical-align:top}
.tg .tg-nrix{text-align:center;vertical-align:middle}
</style>